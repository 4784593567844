@font-face {
	font-family: "Radikal Thin";
	font-display: swap;
	src: url("../fonts/Radikal/Nootype\ -\ Radikal\ Thin.otf") format("opentype");
}

@font-face {
	font-family: "Radikal";
	font-display: swap;
	src: url("../fonts/Radikal/Nootype\ -\ Radikal.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Radikal";
	font-display: swap;
	src: url("../fonts/Radikal/Nootype\ -\ Radikal\ Italic.otf") format("opentype");
	font-style: italic;
	font-weight: normal;
}

@font-face {
	font-family: "Radikal";
	font-display: swap;
	src: url("../fonts/Radikal/Nootype\ -\ Radikal\ Bold.otf") format("opentype") font-weight-bold;
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Radikal";
	src: url("../fonts/Radikal/Nootype\ -\ Radikal\ Bold\ Italic.otf") format("opentype");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "Radikal";
	src: url("../fonts/Radikal/Nootype\ -\ Radikal\ Medium.otf") format("opentype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Radikal";
	src: url("../fonts/Radikal/Nootype\ -\ Radikal\ Medium\ Italic.otf") format("opentype");
	font-weight: 500;
	font-style: italic;
}

:root {
	/* Colors: */
	--bleu-plus-clair: #172b4d;
	--blanc: #ffffff;
	--unnamed-color-1e2535: #1e2535;
	--jaune: #ffc32d;
	--unnamed-color-fec42d: #fec42d;
	--bleu-fonce: #0f1428;
	--gris: #9c9ca8;
	--gris-fonce: #61616a;
	--gris-clair: #e6e6f0;
	--unnamed-color-7b7b7b: #7b7b7b;
	--warning-red: #f44336;
	--green: #04bc0b;
	--red: #e74c3c;
	/* Font/text values */
	--unnamed-font-family-radikal: Radikal;
	--unnamed-font-style-bold: Bold;
	--unnamed-font-style-medium: 500;
	--unnamed-font-style-black: Black;
	--unnamed-font-style-light: Light;
	--unnamed-font-size-14: 14px;
	--unnamed-font-size-15: 15px;
	--unnamed-font-size-16: 16px;
	--unnamed-font-size-18: 18px;
	--unnamed-font-size-20: 20px;
	--unnamed-font-size-35: 35px;
	--unnamed-font-size-55: 55px;
	--unnamed-character-spacing-0: 0px;
	--unnamed-character-spacing-0-2: 0.2px;
	--unnamed-character-spacing-0-35: 0.35px;
	--unnamed-character-spacing--0-55: -0.55px;
	--unnamed-character-spacing-0-15: 0.15px;
	--unnamed-character-spacing-0-23: 0.23px;
	--unnamed-line-spacing-18: 18px;
	--unnamed-line-spacing-20: 20px;
	--unnamed-line-spacing-23: 23px;
	--unnamed-line-spacing-25: 25px;
	--unnamed-line-spacing-28: 28px;
	--unnamed-line-spacing-30: 30px;
	--unnamed-line-spacing-40: 40px;
	--unnamed-line-spacing-60: 60px;
	--unnamed-decoration-underline: underline;
}

body {
	font-family: var(--unnamed-font-family-radikal);
	position: static;
	overflow: auto;
}

iframe {
	z-index: -1 !important;
}

.max-zIndex {
	z-index: 200;
}

.select2-results__option {
	padding: 0.5rem 0.75rem;
	background-color: #fff;
	color: #212529;
	font-size: 0.875rem;
	display: flex;
}

.t2y-hr-light {
	margin-top: 2%;
	margin-bottom: 2%;
}

.t2y-auth-navbar,
.t2y-admin-navbar {
	line-height: 23px;
	font-weight: 500;
	top: 0;
	background-color: #fff !important;
	width: 100%;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1) !important;
	-moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1) !important;
	-webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1) !important;
	position: relative;
	z-index: 888;
}

.t2y-menu-item-link {
	color: #1e2535;
	font-size: 17px !important;
	cursor: pointer;
}

.t2y-menu-item-link:hover {
	color: var(--jaune) !important;
}

.list-group-item:hover div div i {
	color: var(--jaune) !important;
	font-size: 2.5em !important;
}

.t2y-center-title,
.t2y-center-title p {
	width: 100% !important;
	text-align: center !important;
}

.t2y-right-title {
	width: 100% !important;
	text-align: right !important;
}

.t2y-full-width {
	width: 100%;
}

.t2y-height-auto {
	height: auto !important;
}

.t2y-strong {
	font-weight: bold;
	font-size: 18px;
}

.t2y-justify {
	text-align: justify !important;
}

.t2y-title1,
.t2y-title1 p {
}

.t2y-title2,
.t2y-title2 p {
	font: Bold var(--unnamed-font-size-35) / var(--unnamed-line-spacing-40) var(--unnamed-font-family-radikal) !important;
	letter-spacing: var(--unnamed-character-spacing-0-35);
	color: var(--unnamed-color-1e2535);
	text-align: left;
	opacity: 1;
}

.t2y-title2-small,
t2y-title2-small p {
	font-size: 28px !important;
}

.t2y-title2-25px,
t2y-title2-25px p {
	font-size: 25px !important;
}

.t2y-title2.alt,
.t2y-title2.alt p {
	color: var(--blanc) !important;
}

.t2y-title3,
.t2y-title3 p {
	font: var(--unnamed-font-style-medium) 22px/27px var(--unnamed-font-family-radikal) !important;
	color: var(--bleu-plus-clair);
	text-align: left;
	letter-spacing: 0.22px;
	opacity: 1;
}

.t2y-title3.alt,
.t2y-title3.alt p {
	color: var(--blanc) !important;
}

.t2y-title4,
.t2y-title4 p {
	font: normal normal bold 20px/25px Radikal !important;
	color: var(--bleu-plus-clair);
	text-align: left;
	letter-spacing: 0.22px;
	opacity: 1;
}

.t2y-title4.alt,
.t2y-title4.alt p {
	color: var(--blanc) !important;
}

.t2y-title5,
.t2y-title5 p {
	font: normal normal bold 16px/18px Radikal !important;
	color: var(--bleu-plus-clair);
	text-align: left;
	letter-spacing: 0.22px;
	opacity: 1;
}

.t2y-title5.alt,
.t2y-title5.alt p {
	color: var(--blanc) !important;
}

.t2y-paragraph {
	font: var(--unnamed-font-style-medium) var(--unnamed-font-size-16) / var(--unnamed-line-spacing-23) var(--unnamed-font-family-radikal) !important;
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-1e2535);
	text-align: left;
	letter-spacing: 0px;
	opacity: 1;
}

.t2y-paragraph-small {
	color: var(--gris-fonce) !important;
	font-size: var(--unnamed-font-size-14) !important;
}

.t2y-label {
	font: var(--unnamed-font-style-medium) var(--unnamed-font-size-16) / var(--unnamed-line-spacing-23) var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-1e2535);
	text-align: left;
	letter-spacing: 0px;
	opacity: 1;
}

.t2y-logo-stamp::after {
	position: relative;
	left: -31%;
	bottom: 0;
	content: "";
	display: block;
	height: 31px;
	width: 300px;
	-ms-transform: scale(0.4, 0.4);
	/* IE 9 */
	transform: scale(0.4, 0.4);
	filter: grayscale(100%);
	background: url(https://www.truck2you.com/wp-content/uploads/2020/08/logo_truck2you_bleu_transparent.png) no-repeat;
	text-decoration: none;
}

.t2y-link:hover {
	text-decoration: none !important;
}

.t2y-link-small,
.t2y-link-small:hover {
	text-decoration: var(--unnamed-decoration-underline);
	font: var(--unnamed-font-style-medium) var(--unnamed-font-size-15) / var(--unnamed-line-spacing-28) var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0-15);
	color: var(--unnamed-color-1e2535);
	text-align: left;
	text-decoration: underline;
	opacity: 1;
}

.t2y-link-small:hover {
	text-decoration: none;
}

/* LOADING */
.t2y-loading-subcontainer {
	position: absolute;
	top: 0px;
	right: 40%;
	width: 20%;
	height: 10%;
	min-height: 100px;
	/*background-color: #eceaea;
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: center;*/
	z-index: 10000000;
	opacity: 0.4;
	filter: alpha(opacity=40);
}

.t2y-loading-subcontainer div {
	margin: 0 auto;
}

/* MUTED TEXT */
.t2y-muted-text-container {
	padding: 10px;
	margin: 10px;
	border: solid 1px #a9b8c4;
	border-radius: 10px;
}

.t2y-muted-text {
	color: #a9b8c4 !important;
}

.t2y-muted-text-container span.btn-inner--icon i {
	color: #a9b8c4 !important;
}

/* POPOVER TOOLTIP */
.t2y-default-popover {
	background-color: var(--blanc) !important;
	border: solid 1px var(--unnamed-color-1e2535);
	color: var(--unnamed-color-1e2535);
	border-radius: 5px;
}

/* INPUT */
.t2y-button-property-input,
.t2y-button-property-input:hover {
	width: 100%;
	background-color: white !important;
	/*border-color: #1e2635;*/
	border: none;
	color: rgb(30, 38, 53);
	font-size: 14px;
	font-stretch: 100%;
	font-style: normal;
	font-variant-caps: normal;
	font-variant-east-asian: normal;
	font-variant-ligatures: normal;
	font-variant-numeric: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 21px;
	padding: 3px;
	height: 50px;
	text-align: left;
}

.t2y-default-input-container {
	width: 100%;
	background-color: white !important;
	border-color: #1e2635;
	color: #1e2635;
	padding: 1px;
}

.t2y-default-input-container:active {
	width: 100%;
	background-color: white;
	border-color: #1e2635;
	color: #1e2635;
	padding: 0;
}

.t2y-default-input-icon-container {
	color: #ffc42b;
	font-size: 20px;
	float: left;
	width: 1%;
	margin: 0.625rem 1.25rem;
}

.t2y-default-input-control {
	border: none;
	height: 50px;
	margin-top: 0px;
	float: left;
	width: 100%;
	color: #1e2635;
}

.t2y-default-input-control.t2y-with-icon-and-popover {
	width: 60% !important;
}

.t2y-default-input-control.t2y-with-icon,
.t2y-default-input-control.t2y-with-popover,
.t2y-default-input-control.t2y-with-append {
	width: 80% !important;
}

.ty2-default-input-control::placeholder {
	color: rgb(30, 38, 53);
	font-weight: 600;
}

.ty2-default-input-description {
	font-size: 0.875rem;
	font-style: italic;
	text-align: justify;
}

.t2y-rounded {
	border: solid 2px #56607e;
	border-radius: 25px;
	padding: 5px;
}

.input-group > .t2y-default-container {
	width: 80%;
}

.t2y-readonly-icon {
	position: absolute;
	right: -5px;
	top: -12px;
	color: white;
	border: solid 2px white;
	background-color: var(--gris-fonce);
	z-index: 500 !important;
	border-radius: 15px;
	width: 30px;
	height: 30px;
	font-size: 16px;
	padding-top: 2px;
}

.t2y-readonly * > .t2y-default-input-control,
.t2y-readonly * > .t2y-default-input-control,
.t2y-readonly * > .select2-selection {
	background-color: var(--gris-clair) !important;
}

.t2y-readonly > .t2y-default-input-container {
	background-color: var(--gris-clair) !important;
}

.t2y-required::after,
.t2y-required-legend {
	position: absolute;
	top: -10px;
	/* left: 0; */
	content: "*";

	border-radius: 15px;
	width: 20px;
	height: 20px;
	right: 7px;

	font-size: 27px;
	text-align: center;
	color: white;
	background-color: red;
	z-index: 600 !important;
}

.t2y-select-required::after {
	position: absolute;
	content: "*";

	border-radius: 15px;
	width: 20px;
	height: 20px;
	right: 7px;

	margin-top: -49px;
	margin-right: -17px;

	font-size: 27px;
	text-align: center;
	color: white;
	background-color: red;
	z-index: 600 !important;
}

.t2y-required-legend {
	position: relative !important;
}

.t2y-required-with-value::after {
	position: absolute;
	top: -10px;
	/* left: 0; */
	content: "\2713";
	border-radius: 15px;
	width: 20px;
	height: 20px;
	right: 7px;

	font-size: 16px;
	text-align: center;
	background-color: white !important;
	color: green !important;
	border: solid 1px green !important;
	z-index: 600 !important;
}

.t2y-select-required-with-value::after {
	position: absolute;

	content: "\2713";
	border-radius: 15px;
	width: 20px;
	height: 20px;
	right: 7px;

	margin-top: -49px;
	margin-right: -17px;

	font-size: 16px;
	text-align: center;
	background-color: white !important;
	color: green !important;
	border: solid 1px green !important;
	z-index: 600 !important;
}


.t2y-form-group-multiline > .t2y-required::after {
	top: 20px !important;
}

.t2y-form-group-multiline > .t2y-required-with-value::after {
	top: 25px !important;
	right: 10px;
}

.t2y-sticky-thc {
	position: -webkit-sticky !important;
	position: sticky !important;
	top: 0;
}

.form-control-label.t2y-required::after {
	top: -10px !important;
	right: auto !important;
}

/* BUG DE LA CIVILITE */

#good_type-main-container {
	width: 320px !important;
}

#civility_code-main-container,
#contact_civility_code-main-container {
	width: 220px !important;
}

#civility_code-main-container.t2y-required::after,
#contact_civility_code-main-container.t2y-required::after {
	left: 220px !important;
}

#civility_code-main-container.t2y-required-with-value::after,
#contact_civility_code-main-container.t2y-required-with-value::after {
	left: 220px !important;
}

#civility_code-main-container.t2y-default-container .t2y-default-input-container,
#contact_civility_code-main-container.t2y-default-container .t2y-default-input-container,
#good_type-main-container.t2y-default-container .t2y-default-input-container {
	border: none !important;
}

#civility_code-main-container.t2y-default-container .justify-content-center,
#contact_civility_code-main-container.t2y-default-container .justify-content-center,
#good_type-main-container.t2y-default-container .justify-content-center {
	justify-content: left !important;
	padding-left: 15px !important;
}

/* PUBLIC */
.t2y-public-body {
	background: white !important;
	padding: 15px;
}

/* SEARCH FORM */
.t2y-search-form-body {
	background: white !important;
	padding: 15px;
}

.t2y-search-result-body {
	background: var(--unnamed-color-1e2535) 0% 0% no-repeat padding-box !important;
}

.t2y-search-result-connected-container {
	background: var(--unnamed-color-1e2535) 0% 0% no-repeat padding-box !important;
	border-radius: 15px;
	padding: 30px;
	margin-top: -80px;
}

.t2y-search-result-detail-image-card {
	position: absolute !important;
	width: 50%;
	top: 0;
	right: 0;
}

.t2y-search-form {
	background-color: white;
}

.t2y-search-form-embeded {
	background-color: var(--jaune) !important;
	border-radius: 3px;
}

.t2y-search-input,
.t2y-search-input:hover,
.t2y-search-input:active {
	width: 100%;
	height: 60px;
	background: var(--blanc) 0% 0% no-repeat padding-box !important;
	border: 1px solid var(--gris-clair) !important;
	border-radius: 3px;
	box-shadow: inherit !important;
}

.t2y-search-input .btn-inner--icon {
	width: 20px;
	height: 20px;
	font-size: 20px;
	float: left;
	margin-right: 20px;
}

.t2y-search-input .btn-inner--icon img {
	width: 30px;
	height: 30px;
	margin-top: -7px !important;
}

.t2y-search-input .btn-inner--text {
	font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) / var(--unnamed-line-spacing-20) var(--unnamed-font-family-radikal) !important;
	letter-spacing: var(--unnamed-character-spacing-0-23);
	color: var(--gris-fonce);
	text-align: left;
	float: left;
	opacity: 1;
}

/* Gestion des boutons de recherche en mode mobile */
@media screen and (max-width: 480px) {
	.t2y-search-input {
		padding: 5px !important;
	}

	.t2y-search-input .btn-inner--icon {
		width: 20px !important;
		height: 20px !important;
		font-size: 20px !important;
		float: left;
		margin-right: 10px !important;
	}

	.t2y-search-input .btn-inner--icon img {
		width: 20px !important;
		height: 20px !important;
	}

	.t2y-search-input .btn-inner--text {
		font-size: 14px !important;
		width: 85% !important;
		font-weight: 500 !important;
	}

	.t2y-search-input .btn-inner--text p.t2y-decode-entities-defaul-p {
		font-weight: 500 !important;
	}

	.t2y-date-search-input-tab-day,
	.t2y-date-search-input-tab-half-day,
	.t2y-date-search-input-tab-period {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	.t2y-address-search-input-container .btn-inner--icon {
		font-size: 16px !important;
		margin: 10px !important;
	}

	.t2y-address-search-input-container .search-input {
		width: 84% !important;
	}

	/* Réduction des éléments des alerts */
	.alert {
		padding: 0.5rem 1rem;
	}

	.alert .container .row .col-2 {
		max-width: 10% !important;
		flex: 0 0 10% !important;
	}

	.alert .container .row .col-2 * > img {
		width: 30px !important;
	}

	.alert .container .row .col-9 {
		max-width: 80% !important;
		flex: 0 0 80% !important;
	}
}

.t2y-search-filter-property-separator {
	border: 1px solid var(--gris-clair) !important;
	width: 95%;
	margin-top: 20px;
	margin-bottom: 20px;
	opacity: 1;
}

.t2y-search-filter-property-label {
	font: var(--unnamed-font-style-medium) var(--unnamed-font-size-18) / var(--unnamed-line-spacing-23) var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-1e2535);
	text-align: left;
	opacity: 1;
}

.t2y-search-filter-property-ckb-label {
	font: var(--unnamed-font-style-medium) var(--unnamed-font-size-16) / var(--unnamed-line-spacing-23) var(--unnamed-font-family-radikal) !important;
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-1e2535);
	text-align: left;
	opacity: 1;
	width: 100%;
	margin-bottom: 10px;
}

.t2y-search-filter-property-ckb-badge {
	float: right;
	background: none !important;
	font: var(--unnamed-font-style-medium) var(--unnamed-font-size-14) / var(--unnamed-line-spacing-18) var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-7b7b7b);
	text-align: left;
	opacity: 1;
}

.t2y-search-filter-property-ckb-label::before {
	background: var(--blanc) 0% 0% no-repeat padding-box;
	border: 1px solid var(--gris);
	border-radius: 3px !important;
	opacity: 1;
	width: 20px;
	height: 20px;
	margin-top: -5px;
}

.ios-pointer-cursor {
    cursor: pointer;
}


.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background: var(--blanc) 0% 0% no-repeat padding-box;
    border: 1px solid var(--gris) !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
	background-image: url("../img/icons/ckb-check.svg") !important;
	background-position-y: -4px;
	background-position-x: 2px;
	background-size: cover;
}

.t2y-search-sort-group {
	margin-bottom: 10px;
}

.t2y-search-sort-group button.btn {
	border: solid 1px white;
	margin-right: 1px;
	padding: 20px;
	background-color: white;
}

.searchTabFilter {
	display: flex !important;
	flex-direction: row;
	justify-content: center;
}

.jdTZWy .MyLoader_overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background: transparent;
	display: none;
}

.t2y-search-result-container {
	width: 100%;
	background: var(--blanc) 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 3px;
	opacity: 1;
	margin-bottom: 10px;
	padding: 28px;
}

/* Résultat de recherche */
.t2y-search-result-check {
	width: 13px;
	height: 13px;
}

.t2y-search-result-certified {
	margin-left: -10px;
	margin-top: -5px;
}

.t2y-search-result-label {
	font: normal var(--unnamed-font-style-medium) var(--unnamed-font-size-16) / var(--unnamed-line-spacing-23) var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-1e2535);
	text-align: left;
	opacity: 1;
}

.t2y-search-result-extended-date,
.t2y-search-result-extended-distance,
.t2y-search-result-extended-truck {
	background: #ffde88 0% 0% no-repeat padding-box;
	border-radius: 3px;
	opacity: 1;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 3px;
	padding-right: 3px;
}

.t2y-search-result-extended-amount {
	background: #ffde88 0% 0% no-repeat padding-box;
	padding: 10px 5px 10px 5px !important;
	border-radius: 3px;
	opacity: 1;
}

.t2y-search-result-other-prices {
	font: var(--unnamed-font-style-medium) var(--unnamed-font-size-14) / var(--unnamed-line-spacing-18) var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-7b7b7b);
	text-align: left;
	color: #7b7b7b;
	opacity: 1;
}

.page-item.active .page-link {
	z-index: 1;
	color: var(--white);
	background-color: var(--jaune);
	border-color: var(--jaune);
}

/*
TOGGLE CHECKBOX
*/
.custom-toggle input:checked + .custom-toggle-slider {
	border: 1px solid var(--jaune);
}

.custom-toggle input:checked + .custom-toggle-slider:before {
	background: var(--jaune);
	transform: translateX(28px);
}

.custom-toggle input:checked + .custom-toggle-slider:after {
	color: var(--jaune) !important;
}

/*
RADIO BOUTTON
*/
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
	border-color: var(--jaune);
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
	background-color: var(--jaune);
}

/*
BUTTON
*/
.btn-t2y-primary-button-color {
	background: var(--jaune);
}

.t2y-centered-button {
	margin: 0 auto;
}

.t2y-disabled-button,
.t2y-disabled-button:hover,
.t2y-disabled-button:active {
	height: 59px;
	background: var(--gris) 0% 0% no-repeat padding-box !important;
	border: solid 1px var(--gris) !important;
	border-radius: 5px;
	opacity: 0.5;
}

/* BOUTON PRIMAIRE */
.t2y-primary-button,
.t2y-primary-button:hover,
.t2y-primary-button:active {
	height: 89px;
	background: var(--jaune) 0% 0% no-repeat padding-box !important;
	border: solid 1px var(--jaune) !important;
	border-radius: 5px;
	opacity: 1;
}

.t2y-primary-button span.btn-inner--text,
.t2y-primary-button span.btn-inner--icon {
	font: normal normal bold 20px/25px Radikal;
	letter-spacing: 0.2px;
	color: var(--unnamed-color-1e2535);
	text-align: left;
	opacity: 1;
}

/* BOUTON de SUPPRESSION */
.t2y-removed-button {
	color: #f5365c;
	background-color: inherit;
	border: none;
	border-radius: 15px;
	font-size: 13px;
	box-shadow: none !important;
}

.t2y-removed-button:hover,
.t2y-removed-button:active,
.t2y-removed-button:focus {
	color: white !important;
	background-color: #f5365c !important;
	border: #f5365c !important;
	border-radius: 15px !important;
	font-size: 16px !important;
}

.t2y-modal-removed-button {
	color: var(--blanc);
	background-color: var(--warning-red);
	border: var(--warning-red) !important;
}

.t2y-removed-button-alt {
	color: white;
	background-color: var(--warning-red);
	border: none;
	border-radius: 5px;
	font-size: 13px;
	box-shadow: none !important;
}

.t2y-removed-button-alt:hover,
.t2y-removed-button-alt:active,
.t2y-removed-button-alt:focus {
	color: white !important;
	background-color: var(--warning-red) !important;
	border: var(--warning-red) !important;
	border-radius: 5px !important;
	font-size: 16px !important;
}

/* BOUTON SECONDAIRE */
.t2y-secondary-button,
.t2y-secondary-button:hover,
.t2y-secondary-button:active {
	height: 59px;
	background: var(--jaune) 0% 0% no-repeat padding-box !important;
	border: solid 1px var(--jaune) !important;
	border-radius: 5px;
	opacity: 1;
}

.t2y-secondary-button-med,
.t2y-secondary-button-med:hover,
.t2y-secondary-button-med:active {
	height: 40px;
	padding: 0px 10px;
	background: var(--jaune) 0% 0% no-repeat padding-box !important;
	border: solid 1px var(--jaune) !important;
	border-radius: 5px;
	opacity: 1;
}

.t2y-disabled-button span.btn-inner--text,
.t2y-disabled-button span.btn-inner--icon {
	font: Bold var(--unnamed-font-size-18) / var(--unnamed-line-spacing-23) var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-1e2535);
	text-align: left;
	opacity: 1;
}

.t2y-secondary-button span.btn-inner--text,
.t2y-secondary-button span.btn-inner--icon {
	font: Bold var(--unnamed-font-size-18) / var(--unnamed-line-spacing-23) var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-1e2535);
	text-align: left;
	opacity: 1;
}

.t2y-secondary-button-med span.btn-inner--text,
.t2y-secondary-button-med span.btn-inner--icon {
	font: Bold var(--unnamed-font-size-14) / var(--unnamed-line-spacing-14) var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-1e2535);
	text-align: left;
	opacity: 1;
}

/* BOUTON TERTIAIRE */
.t2y-thirdparty-button,
.t2y-thirdparty-button:hover,
.t2y-thirdparty-button:active {
	height: 60px;
	background: var(--bleu-plus-clair) 0% 0% no-repeat padding-box !important;
	border-radius: 5px;
	opacity: 1;
	border: solid 1px var(--bleu-plus-clair) !important;
}

.t2y-thirdparty-button span.btn-inner--text,
.t2y-thirdparty-button span.btn-inner--icon {
	font: normal normal bold 18px/30px Radikal;
	letter-spacing: 0px;
	color: var(--blanc);
	text-align: left;
	opacity: 1;
}

.t2y-thirdparty-alt-button,
.t2y-thirdparty-alt-button:hover,
.t2y-thirdparty-alt-button:active {
	height: 60px;
	background: var(--blanc) 0% 0% no-repeat padding-box !important;
	border: solid 1px var(--blanc) !important;
	border-radius: 5px;
	opacity: 1;
}

.t2y-thirdparty-alt-button span.btn-inner--text,
.t2y-thirdparty-alt-button span.btn-inner--icon {
	font: normal normal bold 18px/23px Radikal;
	letter-spacing: 0px;
	color: var(--unnamed-color-1e2535);
	text-align: left;
	opacity: 1;
}

.t2y-default-button.small {
	height: 41px !important;
}

.t2y-default-button.small span.btn-inner--text,
.t2y-default-button.small span.btn-inner--icon {
	font: normal normal 16px/20px Radikal;
	letter-spacing: var(--unnamed-character-spacing-0);
}

.t2y-removed-button-alt.small {
	height: 41px !important;
}

.t2y-removed-button-alt.small span.btn-inner--text {
	font-size: 15px !important;
	position: relative !important;
}

.t2y-secondary-button.small span.btn-inner--text > * {
	font-size: 14px !important;
	position: relative !important;
	top: 5px !important;
}

.t2y-thirdparty-alt-button.small span.btn-inner--text > * {
	font-size: 14px !important;
	position: relative !important;
	top: 5px !important;
}

.t2y-danger-button,
.t2y-danger-button:hover,
.t2y-danger-button:active {
	height: 59px;
	background: var(--warning-red) 0% 0% no-repeat padding-box !important;
	border: solid 1px var(--warning-red) !important;
	border-radius: 5px;
	opacity: 1;

	color: white !important;
}

button.t2y-dropdown-button {
	height: 42px;
	color: black;
	width: 42px;
	font-size: 22px;
}

.btn-loading {
	min-height: 43px;
}

.t2y-warning-button {
	background-color: var(--warning-red) !important;
	border-color: var(--warning-red) !important;
}

.t2y-warning-color {
	color: var(--warning-red) !important;
}

.t2y-disable-color {
	font-style: italic;
	color: var(--gris) !important;
}

/*
Traduction HTML
*/
.t2y-decode-entities-p {
	line-height: 0.5;
}

.mandate-acceptance > .t2y-decode-entities-p {
	line-height: 1.5 !important;
}

.t2y-html-translation-container > .t2y-decode-entities-p,
.t2y-html-translation-container * > .t2y-decode-entities-p,
.modal-body > .t2y-decode-entities-p {
	line-height: 1.5 !important;
	text-align: justify;
}

.t2y-decode-entities-defaul-p {
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	margin: inherit;
	padding: inherit;
}

.t2y-html-translation-container strong {
	font-weight: bold;
	font-size: 18px;
}

/*
ALERT
*/

.t2y-alert-default {
	background: #d9edf7 0% 0% no-repeat padding-box;
	border: 1px solid #bce8f1;
	border-radius: 10px;
}

.t2y-alert-infos {
	background: #ffde88 0% 0% no-repeat padding-box;
	border-radius: 10px;
	text-transform: none !important;
}

.t2y-alert-default-inner--text,
.t2y-alert-default-inner--icon {
	font: var(--unnamed-font-style-medium) var(--unnamed-font-size-16) / var(--unnamed-line-spacing-23) var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: #31708f;
	text-align: justify;
	font-weight: 100;
	letter-spacing: 0.05em;
	opacity: 1;
}

.t2y-alert-infos-inner--text,
.t2y-alert-infos-inner--icon {
	font: var(--unnamed-font-style-medium) var(--unnamed-font-size-16) / var(--unnamed-line-spacing-23) var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-1e2535);
	text-align: left;
	opacity: 1;
}

.t2y-alert-warning {
	background: #ffd3d3 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 10px;
	opacity: 1;
	text-transform: none !important;
}

.t2y-alert-warning-inner--text {
	font: var(--unnamed-font-style-medium) var(--unnamed-font-size-16) / var(--unnamed-line-spacing-23) var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-1e2535);
	text-align: left;
	opacity: 1;
}

.t2y-alert-success {
	background: #dbffe9 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 10px;
	opacity: 1;
	text-transform: none !important;
}

.t2y-alert-success-inner--text {
	font: var(--unnamed-font-style-medium) var(--unnamed-font-size-16) / var(--unnamed-line-spacing-23) var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-1e2535);
	text-align: left;
	opacity: 1;
}

/*
MODAL
*/

.fullscreen-modal {
	width: 100%;
	height: 100% !important;
	margin: 0;
}

.force-right {
	/* Ajouter pour bug de scroll */
	/*position: fixed;*/
	position: absolute;

	right: 0;
}

.fullscreen-modal > .modal-content {
	border: none;
	border-radius: 0;
	min-height: 100%;
	/* Ajouter pour bug de scroll */
	overflow-y: scroll !important;
}

.fullscreen-modal > .modal-content > .modal-body {
	padding-top: 0.5rem;
}

.renderAddressWorkflowOverlayClass {
	z-index: 9999;
}

.renderAddressWorkflowOverlayClass {
	z-index: 9998 !important;
}

/*
btn-group-toggle
*/
.btn-group-toggle .btn {
	font: var(--unnamed-font-style-medium) var(--unnamed-font-size-16) / var(--unnamed-line-spacing-20) var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-1e2535);
	text-align: center;
	border: 1px solid var(--unnamed-color-1e2535);
	background: var(--blanc) 0% 0% no-repeat padding-box;
	opacity: 1;
}

.btn-group-toggle .btn.disable {
	/*background: var(--unnamed-color-1e2535) 0% 0% no-repeat padding-box !important;*/
	border: 1px solid var(--gris-clair);
	color: var(--gris-clair);
	opacity: 1;
}

/* AFFICHAGE DU CAMION */
.t2y-truck-representation {
	width: 100%;
}

.t2y-truck-representation-small {
	width: 100px;
	height: auto;
	background-size: cover;
}

/* SUR MESURE */
/* Proposition de type de camion */
.t2y-truck-search-repository {
	background: var(--blanc) 0% 0% no-repeat padding-box;
	border: 1px solid var(--gris-clair);
	border-radius: 3px;
	opacity: 1;
	margin: 2px;
	cursor: pointer;
	overflow: hidden;
}

.t2y-truck-search-repository.selected,
.t2y-clickable.selected {
	border: 2px solid var(--jaune);
}

.t2y-clickable.selected {
	padding: 10px;
	border-radius: 10px;
	margin: 5px;
	font-weight: 700;
	color: black;
}

.t2y-truck-search-repository.selected::before {
	content: "";
	position: absolute;
	top: 5px;
	right:
        /*20px;*/ 5px;
	border-width: 16px 0 0 16px;
	border-style: solid;
	border-color: var(--jaune) var(--jaune) #fff #fff;
	background: var(--jaune);
	/* Firefox 3.0 damage limitation */
	display: block;
	width: 0;
}

.t2y-truck-search-repository-image {
	padding-left: 0px !important;
}

.t2y-truck-search-repository-image > img.avatar {
	border: none;
	box-shadow: 0 0 0 0 !important;
	background-color: inherit;
}

.t2y-truck-search-repository-label {
	display: table;
	align-items: center;
	font: var(--unnamed-font-style-medium) 12px/15px var(--unnamed-font-family-radikal);
	letter-spacing: var(--unnamed-character-spacing-0-23);
	color: var(--gris-fonce);
	text-align: left;
	opacity: 1;
	padding-left: 0px !important;
	padding-right: 0px !important;
	/*font-size: 14px !important;*/
}

.t2y-truck-search-repository-label span {
	display: table-cell;
	vertical-align: middle !important;
}

.t2y-truck-search-repository-label.without-image {
	padding-left: 10px !important;
	height: 48px;
}

/* DECORATION */
.t2y-ribbon-container,
.t2y-truck-uncomplete,
.t2y-truck-complete,
.t2y-driver-rating {
	width: 100px;
	height: 100px;
	overflow: hidden;
	position: absolute;
	text-align: left;
	padding-left: 54px;
}

.t2y-truck-uncomplete span {
	position: absolute;
	display: block;
	width: 225px;
	padding: 15px 0;
	background-color: red;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	color: #fff;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	font-size: 14px;
	padding-left: 53px;
}

.t2y-driver-rating span {
	position: absolute;
	display: block;
	width: 225px;
	padding: 5px 0;
	background-color: var(--blanc);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	color: var(--gris-fonce);
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	font-size: 14px;
	padding-left: 53px;
	z-index: 9999;
}

.t2y-truck-complete span {
	position: absolute;
	display: block;
	width: 225px;
	padding: 15px 0;
	background-color: #22bb33;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	color: var(--white);
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	font-size: 14px;
	padding-left: 53px;
}

/* top right*/
.ribbon-top-right {
	top: -10px;
	right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
	border-top-color: transparent;
	border-right-color: transparent;
}

.ribbon-top-right::before {
	top: 0;
	left: 0;
}

.ribbon-top-right::after {
	bottom: 0;
	right: 0;
}

.ribbon-top-right span {
	left: -25px;
	top: 30px;
	transform: rotate(45deg);
}

/* SEARCH RESULT RIBBON */

/* common */
.t2y-ribbon {
	width: 150px;
	height: 145px;
	overflow: hidden;
	position: absolute;
}

.t2y-ribbon::before,
.t2y-ribbon::after {
	position: absolute;
	z-index: -1;
	content: "";
	display: block;
	border: 5px solid #2980b9;
}

.t2y-ribbon span {
	position: absolute;
	display: block;
	width: 225px;
	padding: 15px 0;
	background-color: #3498db;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	color: #fff;
	font: 700 12px/1 "Lato", sans-serif;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	/*text-transform: uppercase;*/
	text-align: center;
}

/* bottom right*/
.t2y-ribbon-bottom-right {
	bottom: -2px;
	right: -2px;
}

.t2y-ribbon-bottom-right::before,
.t2y-ribbon-bottom-right::after {
	border-bottom-color: transparent;
	border-right-color: transparent;
}

.t2y-ribbon-bottom-right::before {
	bottom: 0;
	left: 0;
}

.t2y-ribbon-bottom-right::after {
	top: 0;
	right: 0;
}

.t2y-ribbon-bottom-right span {
	left: -25px;
	bottom: 30px;
	transform: rotate(-45deg);
}

/* Bottom */
.t2y-ribbon-bottom {
	bottom: -120px;
	left: 0px;
	width: 100% !important;
	padding-top: 2px;
	padding-bottom: 2px;
}

.t2y-ribbon-bottom span {
	width: 100% !important;
	padding-top: 7px;
	padding-bottom: 7px;
}

.t2y-ribbon span.refused {
	background-color: red !important;
}

.t2y-ribbon span.orange {
	background-color: #e82 !important;
}

.t2y-ribbon span.green {
	background-color: #2c7 !important;
}

.t2y-ribbon-warning span {
	background-color: #e82 !important;
}

.t2y-ribbon-success span {
	background-color: #2c7 !important;
}

/* top left*/
.item-top-left {
	position: relative;
	top: 0;
	left: 0;
	float: left;
	color: var(--gris-fonce);
}

.item-top-left:hover {
	position: relative;
	top: 0;
	left: 0;
	float: left;
	color: var(--gris-fonce);
}

.ribbon-top-left {
	top: -10px;
	left: -80px;
	width: 200px !important;
	height: 200px !important;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
	border-top-color: transparent;
	border-left-color: transparent;
}

.ribbon-top-left::before {
	top: 0;
	right: 0;
}

.ribbon-top-left::after {
	bottom: 0;
	left: 0;
}

.ribbon-top-left span {
	right: -25px;
	top: 30px;
	transform: rotate(-45deg);
}

/* STEPPER  */
.stepper-container {
	margin-bottom: 50px;
	margin-top: 20px;
	width: 100%;
	display: table;
}

.stepper {
	display: table-row;
}

.stepper:before {
	/*    top: 14px;
    bottom: 0;
    position: absolute;
    content: "";
    width: 66%;
    left: 17%;
    height: 2px;
    background-color: #7283a7; */
}

.stepper.no-before:before {
	content: none;
}

.stepper > .stepper-step {
	display: table-cell;
	text-align: center;
	position: relative;
}

.stepper > .stepper-step-2 {
	width: 50% !important;
}

.stepper > .stepper-step-3 {
	width: 33% !important;
}

.stepper > .stepper-step-4 {
	width: 25% !important;
}

.stepper > .stepper-step-5 {
	width: 20% !important;
}

.stepper > .stepper-step-6 {
	width: 16% !important;
}

.stepper > .stepper-step-7 {
	width: 14% !important;
}

.stepper > .stepper-step-8 {
	width: 12% !important;
}

.stepper > .stepper-step.current > a:before {
	content: "\F3C5";
	font-family: "Font Awesome 5 Free";
	position: absolute;
	top: 0;
	right: -10%;
	top: -20%;
	font-size: 30px;
	color: var(--gris-fonce);
	width: 30px;
	height: 30px;
}

.driver-alert-icon:before {
	content: "\F007";
	font-family: "Font Awesome 5 Free";
	position: relative;
	top: 2%;
	font-size: 20px;
	color: var(--warning-red);
	width: 30px;
	height: 30px;
}

.incident-alert-icon:before {
	content: "\F071";
	font-family: "Font Awesome 5 Free";
	position: relative;
	top: 2%;
	font-size: 20px;
	color: var(--warning-red);
	width: 30px;
	height: 30px;
}

.invoice-alert-icon:before {
	content: "\F27a";
	font-family: "Font Awesome 5 Free";
	position: relative;
	top: 2%;
	font-size: 20px;
	color: var(--warning-red);
	width: 30px;
	height: 30px;
}

.driver-alert-icon:before,
.incident-alert-icon:before,
.invoice-alert-icon:before {
	-webkit-animation: smallMover 0.4s infinite alternate;
	animation: smallMover 0.4s infinite alternate;
}

.stepper > .stepper-step.current > a:before {
	-webkit-animation: mover 0.4s infinite alternate;
	animation: mover 0.4s infinite alternate;
}

.stepper > .stepper-step.current > a:before {
	-webkit-animation: mover 0.4s infinite alternate;
	animation: mover 0.4 s infinite alternate;
}

@-webkit-keyframes mover {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-10px);
	}
}

@keyframes mover {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-10px);
	}
}

@-webkit-keyframes smallMover {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-5px);
	}
}

@keyframes smallMover {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-5px);
	}
}

.stepper > .stepper-step.current > a {
	color: var(--gris-fonce);
	border-color: var(--gris-fonce);
}

.stepper > .stepper-step.current > a > i,
.stepper > .stepper-step.current > label {
	color: var(--gris-fonce);
}

.stepper > .stepper-step > a {
	width: 70px;
	height: 70px;
	border: 2px solid #59698d;
	background-color: white !important;
	color: #59698d !important;
	border-radius: 50%;
	padding: 22px 18px 15px 18px;
	margin-top: -22px;
}

.stepper > .stepper-step.done > a:before {
	content: "\F00C";
	font-family: "Font Awesome 5 Free";
	position: absolute;
	top: 0;
	right: -6%;
	top: -6%;
	font-size: 14px;
	color: white;
	background-color: darkgreen;
	border: solid 1px darkgreen;
	border-radius: 10px;
	width: 20px;
	height: 20px;
}

.stepper > .stepper-step.done > a {
	color: darkgreen;
	border-color: darkgreen;
}

.stepper > .stepper-step.done > a > i,
.stepper > .stepper-step.done > label {
	color: darkgreen;
}

.stepper > .stepper-step.warning > a:before {
	content: "\f12a";
	font-family: "Font Awesome 5 Free";
	position: absolute;
	top: 0;
	right: -6%;
	top: -6%;
	font-size: 14px;
	color: white;
	background-color: red;
	border: solid 1px red;
	border-radius: 10px;
	width: 20px;
	height: 20px;
}

.stepper > .stepper-step.warning > a {
	color: red;
	border-color: red;
}

.stepper > .stepper-step.warning > a > i,
.stepper > .stepper-step.warning > label {
	color: red;
}

.stepper > .stepper-step.not-done > a {
	color: var(--gris) !important;
	border-color: var(--gris) !important;
}

.stepper > .stepper-step.not-done > a > i,
.stepper > .stepper-step.not-done > label {
	color: var(--gris) !important;
}

.stepper > .stepper-step.forbidden {
	opacity: 0.2;
}

.stepper-step-label {
	width: 100%;
	text-align: center;
}

.t2y-hidden {
	display: none !important;
}

.t2y-no-border {
	border: none !important;
}

.t2y-not-found-container {
	margin-bottom: -20%;
}

.t2y-not-found-background {
	width: 200px;
	height: 200px;
	border: solid 1px var(--gris);
	border-radius: 50%;
	display: inline-block;
}

.t2y-not-found-foreground {
	color: var(--jaune);
	position: absolute;
	left: 10%;
	font-size: 150px;
	top: 23%;
}

@media screen and (max-width: 1200px) {
	.t2y-not-found-foreground {
		font-size: 100px;
	}
}

@media screen and (max-width: 800px) {
	.t2y-not-found-foreground {
		font-size: 75px;
	}
}

@media screen and (max-width: 600px) {
	.t2y-not-found-foreground {
		font-size: 50px;
	}
}

.t2y-not-found-search {
	color: var(--gris);
	font-size: 50px;
	position: relative;
	top: 50px;
	left: -40px;
}

.t2y-rdt-icon {
	position: absolute;
	left: 25px;
	z-index: 1;
	font-size: 20px;
	padding-top: 10px;
	padding-left: 10px;
	color: var(--gris-fonce);
}

.t2y-rdt-container * > input {
	/*font-size: 25px;*/
	padding-left: 50px;
}

/* BUG *
/* Suppression des bordure à l'intérieur du DefaultInput type=select */
.t2y-default-input-container.t2y-select {
	border: none !important;
}

/* Passer le bouton de pagination sous le sliding panel */
.page-item.active .page-link {
	z-index: 0;
}

div.t2y-date-range-container div.rdrDateRangePickerWrapper div.rdrDefinedRangesWrapper {
	display: none !important;
}

/* Date du jour dans le calendrier */
.rdrDayToday .rdrDayNumber span:after {
	background: var(--jaune) !important;
}

.rdtMonths .rdtMonth.rdtActive,
.rdtDay.rdtToday.rdtActive,
.rdtDay.rdtActive,
.rdtYears .rdtYear.rdtActive {
	background-color: var(--jaune) !important;
}

/* Bug de l'arrière plan du panel de droite */
.ReactModal__Overlay {
	z-index: 888;
}

.bg-gradient-info {
	background-color: var(--blanc) !important;
	/*background: linear-gradient(87deg, var(--jaune) 0,var(--jaune) 100%) !important;*/
}

.bg-default {
	/*background-color: #E6E6F0 !important;*/
}

.bg-secondary {
	box-shadow: none !important;
	background-color: white !important;
}

.t2y-bg-auth-layout {
	background-color: var(--blanc) !important;
}

.t2y-main-auth-content,
.t2y-main-admin-content {
	background-image: url(https://res.cloudinary.com/dl2d3weuy/image/upload/f_auto,q_auto/v1600676887/Wordpress/expediteur-section2-fleche-camion-min_rhx5kp.png),
		url(https://res.cloudinary.com/dl2d3weuy/image/upload/f_auto,q_auto/v1600098809/Wordpress/Traits-Truck2You-grisclair-1.png) !important;
	background-size: 25%, 25%;
	background-position: left center, right top;
	background-repeat: no-repeat;
	background-color: #fff !important;
	min-height: 100vh;
	padding-top: 17px;
}

.bg-info {
	background-color: transparent !important;
	/*var(--jaune) !important;*/
}

.logged-as p {
    color: var(--jaune);
    font-size: 17px !important;
    cursor: default !important;
    background-color: #1e2535 ;
    border-radius: 10px;
    padding: 5px 10px;
    margin-bottom: 0;
}

.logged-as:hover{
    color: var(--jaune);
}

.admin-quit{
    border-radius: 10px;
    background-color: red;
    color: #FFFFFF;
    padding: 5px !important;
    text-align: center;
}

body,
footer {
	background-color: #e6e6f0 !important;
}

.fill-default {
	fill: var(--unnamed-color-1e2535);
}

.map-point div div div canvas {
	border-radius: 15px;
	border: solid 1px var(--gris-fonce);
}

.confirm-btn-success {
	background-color: var(--jaune) !important;
	border: var(--jaune);
}

.t2y-timeline-title {
	padding: 2%;
	border: solid 3px #ffc32c;
	border-radius: 3mm 3mm 3mm 3mm;
	-moz-border-radius: 3mm 3mm 3mm 3mm;
	background-color: white;
}

.t2y-timeline-subtitle {
	padding: 1%;
	border: solid 2px #ffc32c;
	border-radius: 3mm 3mm 3mm 3mm;
	-moz-border-radius: 3mm 3mm 3mm 3mm;
	background-color: white;
}

.t2y-not-found {
	font-size: 40px;
	color: darkgrey;

	padding: 22px;
	border: solid 5px darkgrey;
	width: 100px;
	height: 100px;
	border-radius: 50%;
}

.t2y-not-found:after {
	content: "";
	width: 181px;
	height: 0;
	border-bottom: 4px dashed darkgrey;
	-webkit-transform: translateY(20px) translateX(5px) rotate(-26deg);
	position: absolute;
	top: 55px;
	left: -5px;
	transform: rotate(45deg);
}

/* Bug SELECT2 */
.select2-selection__rendered {
	text-align: left;
}

.show {
	transition: opacity 100ms;
}

.modal.fade {
	-webkit-transition: opacity 0.1s linear, top 0.1s ease-out;
	-moz-transition: opacity 0.1s linear, top 0.1s ease-out;
	-ms-transition: opacity 0.1s linear, top 0.1s ease-out;
	-o-transition: opacity 0.1s linear, top 0.1s ease-out;
	transition: opacity 0.1s linear, top 0.1s ease-out;
}

/* NOTIFICATION ANIMATION */

.t2y-required-fields label ul li {
	padding-left: 20px;
	width: 100%;
	text-align: left;
}

.t2y-required-fields label ul li::before {
	content: "\2713";
	color: inherit;
	font-size: 14px;
	color: var(--gris-fonce);
}

.t2y-required-properties-card-badge {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	text-align: center;
	font-size: 14px;
	background-color: white;
}

@media (max-width: 1400px) {
	.t2y-required-properties-card-badge {
		font-size: 10px;
	}
}

.select2-container--disabled,
.select2-container--disabled * > .select2-selection {
	background: var(--gris-clair) !important;
	border: var(--gris-clair) !important;
}

.order_ORDER_STATE_WAITING_TRANSPORTER_VALIDATION .card-header {
	background-color: var(--gris-clair);
	font-style: italic;
}

/* MULTISELECT */


ul.optionContainer li:hover {
	background-color: #fcf8ed !important;
	color: "#0f1428" !important;
}


/* Eviter toutes les options selectionnées sur une ligne et forcer la liste des options par dessus */
#drivers-main-container .btn,
#trucks-main-container .btn,
#addresses-main-container .btn,
#collaborators-main-container .btn {
	will-change: inherit !important;
	white-space: normal !important;
	z-index: 1 !important;
}

#drivers-main-container .btn > div.optionListContainer,
#trucks-main-container .btn > div.optionListContainer,
#addresses-main-container .btn > div.optionListContainer,
#collaborators-main-container .btn > div.optionListContainer,
#shipper-customer-contacts-input-main-container .btn > div.optionListContainer {
	z-index: 9999 !important;
}

.nav-link-icon,
.t2y-clickable {
	cursor: pointer;
	z-index: 2;
	position: relative;

}

.nav-link-icon i,
.nav-link-icon * > i {
	font-size: 25px;
}

/* Css du breadCumb */
ol.breadcrumb {
	background: white;
}

.breadcrumb-item {
	font-size: 16px;
}

.breadcrumb-item a p {
	font-size: 14px;
	text-decoration: underline;
}

.breadcrumb-item a,
.breadcrumb-item a:hover,
.breadcrumb-item a:active {
	color: black;
}

.planning-table > tbody > tr > td {
	padding-top: 0.1rem !important;
	padding-bottom: 0.1rem !important;
}

.stickyHeader {
	position: relative !important;
	z-index: 7777;
	width: 100vw;
	background: white;
	animation: slideDown 200ms ease-in;
}

/* Fixed Headers */
.planning-headers {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 2;
	background-color: var(--blanc);
}

th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 2;
}

th[scope="row"] {
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	z-index: 1;
}

th[scope="row"] {
	vertical-align: top;
	color: inherit;
	background-color: white;
}

table:nth-of-type(2) th:not([scope="row"]):first-child {
	left: 0;
	z-index: 3;
}

.t2y-table-fixed {
	table-layout: fixed;
}

tr.t2y-warning-content td {
	color: var(--warning-red);
}

/* Strictly for making the scrolling happen. */

th[scope="row"] + td {
	min-width: 24em;
	background-color: white;
}

th[scope="row"] {
	min-width: 20em;
}

.planning-container > .table-responsive {
	overflow: visible !important;
}

.t2y-sliding-filters .slide-pane__header .slide-pane__close {
	position: absolute !important;
	right: 10px;
	font-size: 30px;
}

.t2y-fab {
	position: fixed !important;
	left: 5% !important;
	bottom: 5% !important;
	z-index: 9999;
	border-radius: 50%;
}

.t2y-fab:hover {
	background-color: white;
	color: var(--jaune);
	position: fixed !important;
	left: 5% !important;
	bottom: 5% !important;
	z-index: 9999;
	border-radius: 50%;
}

.t2y-search-filter-container-not-checked .t2y-search-filter-property-ckb-label::before {
	border-color: var(--gris) !important;
}

.t2y-search-filter-container-not-checked .t2y-search-filter-property-ckb-label,
.t2y-search-filter-container-not-checked .t2y-search-filter-property-ckb-label span {
	color: var(--gris) !important;
	font-weight: 200 !important;
}

.btn-group-toggle.btn .active {
	background-color: var(--jaune) !important;
	color: rgb(30, 37, 53) !important;
}

[data-toggle="buttons"]:not(.btn-group-colors) > .btn.active.searchFilterTabBtn {
	background-color: var(--jaune) !important;
	color: var(--bleu-plus-clair) !important;
	border: 1px solid var(--bleu-plus-clair);
}

[data-toggle="buttons"]:not(.btn-group-colors) > .btn.searchFilterTabBtn {
	border: 1px solid var(--bleu-plus-clair);
}

[data-toggle="buttons"]:not(.btn-group-colors) > .btn.active.tabBtn {
	background-color: var(--blanc) !important;
	color: rgb(30, 37, 53) !important;
	border-top: 5px solid #ffc32d;
	border-right: 2px solid #e0e0e0;
	border-left: 2px solid #e0e0e0;
	border-bottom: 0px solid #e0e0e0;
	border-radius: 5px 5px 0px 0px;
}

[data-toggle="buttons"]:not(.btn-group-colors) > .btn.tabBtn {
	background-color: #f6f9fc;
	cursor: pointer;
	box-shadow: none;
	border: 0;
	margin: 0;
	border-radius: 0px 0px 0px 0px;
	border-bottom: 2px solid #e0e0e0;
}

.btn-group-toggle .btn.active.multiChoiceBtn {
	background-color: var(--bleu-plus-clair) !important;
	color: rgb(255, 255, 255) !important;
	border: 1px solid var(--bleu-plus-clair);
}

.btn-group-toggle .btn.multiChoiceBtn {
	border: 1px solid var(--bleu-plus-clair);
}

/*
  .badge-secondary {
      background-color: var(--jaune);
  }*/

.INCIDENT_IMPACT_OK {
	color: green !important;
}

.INCIDENT_IMPACT_DELAY {
	color: orange !important;
}

.INCIDENT_IMPACT_KO {
	color: red !important;
}

.badge-INCIDENT_IMPACT_OK {
	background-color: green !important;
	color: white !important;
}

.badge-INCIDENT_IMPACT_DELAY {
	background-color: orange !important;
	color: white !important;
}

.badge-INCIDENT_IMPACT_KO {
	background-color: red !important;
	color: white !important;
}

.t2y-badge-warning {
	background-color: orange;
	text-transform: inherit !important;
	font-size: 1rem !important;
	font-weight: 300;
}

.title-INCIDENT_IMPACT_KO {
	color: red !important;
	font-size: 16px !important;
}

.time-INCIDENT_IMPACT_KO {
	color: red !important;
	font-size: 13px !important;
}

.btn-dropdown-zindex {
	clear: both;
	position: unset !important;
	will-change: unset !important;
}

.ow {
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
}

.stripe-input-element,
.stripe-input-element:focus,
.stripe-input-element:active {
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 2px;
	font-size: 14px;
	font-family: var(--unnamed-font-family-radikal);
}

.stripe-input-element.t2y-required-with-value::after,
.stripe-input-element.t2y-required::after {
	top: -8px !important;
	right: -5px;
}

.t2y-data-grid-row-error .rdg-cell {
	background-color: lightyellow;
	/*color: var(--warning-red);*/
}

.t2y-data-grid-row-error .rdg-cell:hover {
	/*background-color: red;*/
	/*color: var(--warning-red);*/
}

.errorMessage {
	color: #fb6340;
}

.straightLine,
.hrLine {
	position: fixed;
	background-color: var(--jaune);
	z-index: 8888;
}

.planning-header-date-selected {
	background-color: var(--jaune) !important;
	color: var(--gris-fonce) !important;
	border-radius: 10px;
}

.planning-line-title,
.planning-td {
	vertical-align: middle !important;
}

.planning-td-hover {
	/*background-size:  100% 100%;
    transform:scale(1.4545,1.34);
    transform-origin:center;*/
	z-index: 9999;

	outline: solid 5px var(--jaune);
	outline-offset: -5px;
}

.t2y-order-old-price {
	text-decoration: line-through;
	color: var(--warning-red);
	font-size: 14px;
}

.t2y-order-new-price {
}

.allow_call_for_bids_img_flow {
	width: 116%;
	max-height: 100%;
	position: absolute;
	left: -61px;
	top: 0px;
}

.allow_call_for_bids_next_day_img_flow {
	width: 116%;
	max-height: 100%;
	position: absolute;
	left: -51px;
	top: 0px;
}

.text-yellow {
	color: var(--jaune);
}

.text-grey {
	color: var(--gris-fonce);
}

.text-blue {
	color: var(--bleu-plus-clair);
}

.text-dark {
	color: var(--bleu-fonce);
}

.bg-grey {
	background: var(--gris-fonce);
}

.bg-faded {
	background: #f3f3f0;
}

.bg-white {
	background: #fff;
}

.t2y-btn-primary {
	background: var(--jaune);
	border-radius: 5px;
	border: none;
	padding: 10px 20px 10px 20px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	font-size: 1.2em;
	color: var(--bleu-plus-clair);
}

.t2y-btn-primary:hover {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	color: black;
}

.t2y-btn-primary:visited {
	color: var(--bleu-plus-clair);
}

.t2y-btn-primary:disabled {
	color: var(--gris);
	background: var(--gris-clair);
}

.t2y-btn-primary:disabled:hover {
	color: var(--gris);
	background: var(--gris-clair);
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	cursor: default;
}

.t2y-btn-sm-primary {
	background: var(--jaune);
	border-radius: 5px;
	border: none;
	padding: 8px 16px 8px 16px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	font-size: 1.2em;
	color: var(--bleu-plus-clair);
}

.t2y-btn-sm-primary:hover {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	color: black;
}

.t2y-btn-sm-primary:visited {
	color: var(--bleu-plus-clair);
}

.t2y-btn-sm-primary:disabled {
	color: var(--gris);
	background: var(--gris-clair);
}

.t2y-btn-secondary {
	background: var(--blanc);
	border-radius: 5px;
	border: none;
	padding: 10px 20px 10px 20px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	font-size: 1.2em;
	color: var(--bleu-plus-clair);
}

.t2y-btn-secondary:hover {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	color: black;
}

.t2y-btn-secondary:active {
	background: var(--gris-clair);
}

.t2y-btn-secondary:disabled {
	color: var(--gris);
	background: var(--gris-clair);
}

.t2y-btn-secondary:disabled:hover {
	color: var(--gris);
	background: var(--gris-clair);
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	cursor: default;
}

.t2y-input-number {
	display: inline-block;
	padding: 2px;
	width: 80%;
	height: 2em;
	border: none;
	border-bottom: 1px solid var(--bleu-fonce);
	background: #e6e6f0;
	padding-left: 0.5em;
}

.t2y-euro-container {
	background: var(--jaune);
	color: var(--bleu-fonce);
	border-radius: 0 5px 5px 0;
	right: -3px;
	border-left: none;
	height: 2em;
	width: 20%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	border-bottom: 1px solid var(--bleu-fonce);
}

.t2y-siret-input {
	display: inline-block;
	border: none;
	border: 1px solid var(--bleu-fonce);
	background: #ffffff;
	text-align: left;
	font-size: 1.2em;
	padding-left: 1.3em;
	color: var(--bleu-fonce);
}

.manual-input-siret-container{
    transition: all ease 0.2s;
}

.box {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  }
  .box:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  }

.t2y-siret-input::placeholder {
	color: #7b7b7b;
}

.t2y-search-icon-container {
	background: var(--jaune);
	color: var(--bleu-fonce);
	border-radius: 0 5px 5px 0;
	right: -3px;
	border-left: none;
	width: 15%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	border-bottom: 1px solid var(--bleu-fonce);
	padding: 0.5em;
}

.t2y-search-icon-container:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	cursor: pointer;
}

.t2y-card-title {
	text-align: left;
	padding-left: 1em;
	color: var(--bleu-fonce);
	/* background: rgb(255,255,255);
    background: linear-gradient(297deg, rgba(255,255,255,1) 12%, rgba(255,195,45,1) 100%); */
	border-radius: 5px;
}

.t2y-civility-male-button {
	display: inline-block;
	border: 1px solid var(--gris-fonce);
	border-radius: 5px 0 0 5px;
	background: #fff;
	padding: 0.5em 1em 0.5em 1em;
	cursor: pointer;
}

.t2y-civility-female-button {
	display: inline-block;
	border: 1px solid var(--gris-fonce);
	border-radius: 0 5px 5px 0;
	background: #fff;
	padding: 0.5em 1em 0.5em 1em;
	cursor: pointer;
}

*:is(.t2y-civility-female-button, .t2y-civility-male-button).active {
	background: var(--jaune);
}

.sha {
	box-shadow: none;
}

.sha:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.input-required {
	position: relative;
}

.not-valid-icon {
	position: absolute;
	text-align: center;
	top: 23px;
	right: 0.45em;
	width: 1em;
	height: 1em;
	background: #ff0000;
	color: white;
	font-size: 1.2em;
	border-radius: 50%;
	z-index: 100;
}

.valid-icon {
	position: absolute;
	text-align: center;
	top: 23px;
	right: 0.45em;
	width: 1em;
	height: 1em;
	background: #fff;
	color: #008001;
	font-size: 1.2em;
	border-radius: 50%;
	z-index: 100;
	border: 1px solid #008001;
	line-height: 1em;
	padding-top: 0.1em;
}

.locked-icon {
	position: absolute;
	text-align: center;
	top: 22px;
	right: 7px;
	width: 1.4em;
	height: 1.4em;
	background: #61616a;
	color: #fff;
	border-radius: 50%;
	z-index: 100;
	border: 1px solid #fff;
	line-height: 1em;
	padding-top: 0.2em;
}

.t2y-switch-button-checked {
	border: 1px solid var(--jaune);
}

.t2y-switch-button-unchecked {
	border: 1px solid #adb5bd;
}

.t2y-info-icon {
	display: inline-block;
	background: black;
	border-radius: 8px;
	font-size: 10px;
	width: 16px;
	height: 16px;
	color: white;
	text-align: center;
	vertical-align: top;
	font-family: Georgia, serif;
	font-weight: 700;
	cursor: help;
}

.t2y-info-icon:hover {
	color: white;
	text-decoration: none;
}

[data-title]:hover:after {
	opacity: 1;
	transition: all 0.1s ease 0.1s;
	visibility: visible;
}
[data-title]:after {
	content: attr(data-title);
	background-color: #fff;
	color: var(--bleu-fonce);
	font-size: 120%;
	position: absolute;
	padding: 4px 8px 4px 8px;
	top: -4em;
	left: -30vw;
	white-space: nowrap;
	box-shadow: 1px 1px 3px #222222;
	opacity: 0;
	border: 1px solid #111111;
	z-index: 99999;
	visibility: hidden;
	border-radius: 5px;
	max-width: 50vw;
	font-family: Arial, Helvetica, sans-serif;
	white-space: pre-line;
}
[data-title] {
	position: relative;
}

.t2y-modal-close-icon {
	color: var(--gris);
	margin-top: 0.2em;
	margin-right: 0.8em;
	font-weight: 700;
	font-size: 1.2em;
}

.t2y-modal-close-icon:hover {
	text-shadow: 2px 2px 5px var(--gris);
	color: var(--gris-fonce);
}

.t2y-modal-overlay {
	background: rgba(86, 90, 91, 0.7);
}

.t2y-modal {
	background: #b2dbbf;
	color: var(--bleu-fonce);
	min-width: 60vw;
	min-height: 20vh;
	border-radius: 1.2em;
}

.t2y-modal-content {
	min-height: 50vh;
	margin: 0;
}

.not-visible {
	display: none;
}

.orders-grid-wrapper {
	display: flexbox;
	flex-direction: row;
}

.text-decoration-none {
	text-decoration: none !important;
}

.t2y-login-input-email {
	display: inline-block;
	padding: 2px;
	width: 90%;
	border: none;
	border-bottom: 1px solid var(--bleu-fonce);
	background: #e6e6f0;
	padding-left: 0.5em;
	border-radius: 0;
}

.t2y-login-input-password {
	display: inline-block;
	padding: 2px;
	width: 90%;
	border: none;
	border-bottom: 1px solid var(--bleu-fonce);
	background: #e6e6f0;
	padding-left: 0.5em;
	border-radius: 0;
}

.t2y-input-icon-left {
	background: var(--jaune);
	color: var(--bleu-fonce);
	border-radius: 5px 0 0 5px;
	right: -3px;
	border-left: none;
	min-width: 10%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	border-bottom: 1px solid var(--bleu-fonce);
	height: 100%;
}

.t2y-password-eye-icon {
	position: absolute;
	right: 2em;
}
.t2y-select-input {
	border: 1px solid #1e2635;
	border-radius: 4px;
}

.t2y-select-input:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.t2y-call-us {
	color: var(--bleu-fonce);
	text-decoration: underline;
}

.t2y-form-footer {
	border-top: 1px solid var(--gris-clair);
}

.t2y-form-header {
	border-bottom: 1px solid var(--gris-clair);
}

.t2y-btn-sm-success {
	background: var(--green);
	border-radius: 5px;
	border: none;
	padding: 5px 10px 5px 10px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	font-size: 1em;
	color: var(--bleu-plus-clair);
}

.t2y-btn-sm-success:hover {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	color: var(--blanc);
}

.t2y-btn-sm-success:disabled {
	color: var(--gris);
	background: #505e58;
}

.t2y-btn-sm-error {
	background: var(--red);
	border-radius: 5px;
	border: none;
	padding: 5px 10px 5px 10px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	font-size: 1em;
	color: var(--bleu-plus-clair);
}

.t2y-btn-sm-error:hover {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	color: var(--blanc);
}

.t2y-btn-sm-error:disabled {
	color: var(--gris);
	background: #505e58;
}

.t2y-form-options-button {
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	background: white;
	padding: 8px;
	border-radius: 5px;
	border: none;
	width: 3em;
	height: 3em;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.3em;
}

.t2y-form-options-button:hover {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
}

.text-red {
	color: #ff0000;
}

.ribbon {
	position: absolute;
	inset: 0 auto auto 0;
	background: #e82;
	transform-origin: 100% 0; /* or top left */
	transform: translate(-29.3%) rotate(-45deg);
	box-shadow: 0 0 0 999px #e82;
	clip-path: inset(0 -100%);
	color: white;
	font: 700 1.3em "Lato", sans-serif;
	padding: 3px 0 3px 0;
	/* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.t2y-dropzone-container {
	border: 1px dashed var(--gris-fonce);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100px;
	width: 100%;
	margin: auto;
}

.t2y-dropzone-container:hover {
	cursor: pointer;
}

.t2y-collaborator-image {
	height: 120px;
	width: auto;
	background: #adb5bd;
	border-radius: 4px;
}

.user-alert-container{
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	top: 250px;
	margin-top: 50px;
	max-width: 655px;
	max-height: 80%;
}

.phone-description-ribbon {
	width: 500px;
	height: 20px;
	background-color: #22bb33;
	transform: rotate(-45deg);
	position: absolute;
	top: -100px;
	left: -100px;
	z-index: 50;
}

.phone-description-ribbon-disabled {
	width: 500px;
	height: 20px;
	background-color: #6a6a6a99;
	transform: rotate(-45deg);
	position: absolute;
	top: -100px;
	left: -100px;
	z-index: 50;
}

.phone-description-ribbon p {
	color: white;
	padding-left: 60px;
	font-size: 12px;
}

.phone-description-ribbon-disabled p {
	color: white;
	padding-left: 60px;
	font-size: 12px;
}

.phone-description-text {
	color: black;
	font-size: 15px;
}

.phone-description-card {
	overflow: hidden;
	border: #1111111c 1px solid;
	margin: 0;
}

#draft-warning{
	margin: 10px;
	display: none;
}

#draft-warning p{
	color: red;
}

.d-none{
	display: none;
}

.unprecise-addresse-modal{
	position: absolute;
	width: 880px;
	min-height: 420px;
	background-color: white;
	z-index: 5;
	left: -65px;
	border-radius: 10px;
	display: flex;
	padding: 15px;
	flex-direction: column;
}

.unprecise-addresse-content{
	width: 100%;
	height: 80%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
}

.unprecise-addresse-input-container{
	padding: 5px;
	width: 60%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.unprecise-addresse-input-container-header{
display: flex;
flex-direction: row;
justify-content: center;
margin-bottom: 15px;
}

.unprecise-addresse-input-container-header img{
margin-right: 10px;
}

.unprecise-addresse-input-container-header h2{
	text-align: center;
	vertical-align: middle;
	padding-top: 5px;
	margin: 0;
}


.unprecise-addresse-map-container{
	padding: 5px;
	width: 40%;
	height: 100%;
	display: flex;
	justify-content: center;
}

.unprecise-addresse-footer{
	width: 100%;
	height: 20%;
	justify-content: center;
	display: flex;
	align-items: center;
}

.unprecise-addresse-input-wrapper{
	padding: 15px;
	width: 100%;
	height: 100%;
}

.valid-input::after {
	position: relative;
	display: block;
	top: 10px;
	left: 460px;
	content: "\2713";
	border-radius: 15px;
	width: 20px;
	height: 20px;
	font-size: 16px;
	text-align: center;
	background-color: white !important;
	color: green !important;
	border: solid 1px green !important;
	z-index: 600 !important;
}

.invalid-input::after{
	position: relative;
	display: block;
	top: 10px;
	left: 460px;
	content: "*";
	border-radius: 15px;
	width: 20px;
	height: 20px;
	font-size: 27px;
	text-align: center;
	color: white;
	background-color: red;
	z-index: 600 !important;
}

.form-control:disabled, .form-control[readonly] {
	background-color: white !important;
}

.btn .selectWithoutTranslate:hover {
	transform: translateY(0px) !important;
}

.float-icon-right {
	position: absolute;
	right: 30px;
}

.caret{
	font-size: 15px;
	position: absolute;
	left: 23px;
	top: 10px;
}

.open{
	transform: rotate(90deg);
}