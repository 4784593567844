.legendWrapper{
	display: flex; 
	justify-content: center;
	width: 100%;
	padding: 20px;
}

.legendContainer{
	width: 100%;
	display: flex;
	justify-content: space-evenly
}

.legendPanel{
	padding: 20px;
	box-shadow: 5px 5px 44px -2px rgba(0,0,0,0.09); border-radius: 8px
}

.legendPanel h3{
font-size: 15px;
}

.legendBody{
	display: flex;
	flex-direction: row;
	padding: 5px;
}

.legendRow{
	display: flex;
	flex-direction: row;
	align-items: baseline;
	margin-right: 15px;
	margin-left: 5px;
}

.legendRow p {
font-style: italic;
font-size: 13px;
margin-bottom: 0px;
}

.legendColor{
	width: 10px;
	height: 10px;
	border: 1px solid;
}