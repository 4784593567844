.admin-wrapper{
  width: 100%;
  height: 100%;
  min-width: 100px;
  min-height: 100vh;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.admin-searchbar{
  width: 20%;
  height: auto;
  min-height: 100px;
  min-width: 200px;
  background-color: rgb(255, 255, 255);
  padding-top: 10px;
  border: 1px solid grey;
  border-radius: 10px;
}

.admin-content{
  width: 100%;
  height: 100%;
  min-width: 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.component-container{
  height: 400px;
  min-width: 400px;
  width: fit-content;
  margin: 5px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.component-options{
  padding: 15px;
}

.search-item{
  padding: 12px;
  margin-bottom: 5px ;
  height: 30px;
  cursor: pointer;
}