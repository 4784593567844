.dz-message {
    z-index:20 !important;
}

.filterable-hide{
    display:none;
}

/*
NAVBAR
*/
.navbar-dark .navbar-nav .nav-link i{
    font-size: 30px;
}

/*
    RC TIMEPICKER
*/
.rc-time-picker-input {
    border:none !important;
}

/* VALIDATION */
.not-visible-and-exclude-from-validation {
    display:none !important;
}


/* group */
.input-group-merge.is-invalid {
    border:solid 1px #fb6340 !important;
}

/* 
    SELECT2 
*/
.is-invalid .select2-selection {
    border-color: #fb6340 !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    display: inline-flex;
    padding: 0 .5rem;
    margin: 0 0 .25rem .25rem;
    background-color: inherit !important;
    border: dashed 1px #adb5bd !important;
    border-radius: 0.25rem;
    line-height: 1.5rem;
    font-size: 0.875rem;
    color: black !important;
}

.selection {
    width:100% !important;
}

.select2-search__field {
    min-width:200px !important;
}


.select2-container, .select2-dropdown, .select2-search, .select2-results {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}


.uploaded_image_url {
    width:200px  !important;
    height:auto !important;
}

.missions_truck_image_url {
    width:150px  !important;
    height:auto !important;
}

.modal-xl {
    max-width: 90% !important;
}


.rdtPicker {
    right: 0;
}

.arrival_time .row .col-12 .rdtOpen .rdtPicker {
    top: -180px !important;
}
  

.NOT_AVAILABLE, .NOT_AVAILABLE_EVENT {
    background-color: var(--gris-fonce);
    color:white;
    z-index: 1 !important;
    /*margin: 10px !important;*/
    
   /* background: */
    /* On "top" */
  /*repeating-linear-gradient(
    29deg,
    transparent,
    transparent 5px,
    white 2px,
    white 20px
  ),*/
  /* on "bottom" */
  /*linear-gradient(
    to bottom,
    red,
    red
  );*/
}
        
.AVAILABLE_EVENT, .AVAILABLE{
    background-color: white;
}

.NOT_AVAILABLE_EVENT{
    /*border-bottom:solid 5px red; */
}

.AVAILABLE_EVENT{
    /*border-bottom:solid 5px forestgreen; */
    /*background-color: forestgreen;color:white*/
}

.PUBLIC_HOLIDAY 
{
    background-color:var(--gris-fonce);
}

.CLOSED {
    background-color:var(--gris-clair);
}


.DATE_LOADING {
    background-color: var(--jaune);
}

.DRIVER:before {
    font-family: "Font Awesome 5 Free";
    content: "\f4fe";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
 }

.TRUCK:before {
    font-family: "Font Awesome 5 Free";
    content: "\f4df";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
 }

.DRIVERTRUCK:before {
    font-family: "Font Awesome 5 Free";
    content: "\f4fe";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
 }

 .DRIVERTRUCK:after {
    font-family: "Font Awesome 5 Free";
    content: "\f4df";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
 }

.planning-header {
    writing-mode: sideways-rl important; /* +90°: use 'tb-rl' */
    text-align:center;
    white-space:nowrap;
    background-color: white;
    border-bottom: solid 3px black !important;
    /*transform: rotate(-60deg);*/
}

.table.planning-table td, .table.planning-table th {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    text-align: center;
}

td.selected {
    background: none;
    background-color: var(--jaune) !important;
}


/**
    Bordure sur séléction
*/
.form-control:focus, .select2-container--focus /*, .select2-container--open*/  {
    border-left: solid 8px rgba(0, 0, 0, 0.075);
    border-top: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
    border-right: 1px solid #dee2e6 !important;
    /*box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(255, 100, 255, 0.5);*/
}

.select2-container .select2-selection--single:focus{
    border:none !important;
}


.input-right-content
{
    position: absolute;
    top: 10px;
    right: 30px;
}

.input-with-right-content
{
    padding-right: 2.5em;
}


.dropdown-menu-xl {
    min-width: 800px;
    border-radius: 0.4375rem;
}

.react-datepicker-popper { z-index: 1001 !important }
/*
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color : grey;
}*/






.rapid-search-overlay {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
  }
  
  .rapid-search-overlay-content {
    position: relative;
    top: 46%;
    width: 80%;
    text-align: center;
    margin-top: 30px;
    margin: auto;
  }
  
  .rapid-search-overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    cursor: pointer;
    color: white;
  }
  
  .rapid-search-overlay .closebtn:hover {
    color: #ccc;
  }
  
  .rapid-search-overlay input[type=text] {
    padding: 15px;
    font-size: 17px;
    border: none;
    float: left;
    width: 80%;
    background: white;
  }
  
  .rapid-search-overlay input[type=text]:hover {
    background: #f1f1f1;
  }
  
  .rapid-search-overlay button {
    float: left;
    width: 20%;
    padding: 15px;
    background: #ddd;
    font-size: 17px;
    border: none;
    cursor: pointer;
  }
  
  .rapid-search-overlay button:hover {
    background: #bbb;
  }